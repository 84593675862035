import { z } from 'zod'

export const UsernameSchema = z
	.string({ required_error: 'Username is required' })
	.min(3, { message: 'Username is too short' })
	.max(20, { message: 'Username is too long' })
	.regex(/^[a-zA-Z0-9_-]+$/, {
		message:
			'Username can only include letters, numbers, an underscore, and a dash',
	})
	// users can type the username in any case, but we store it in lowercase
	.transform((value) => value.toLowerCase())

export const PhoneNumberSchema = z
	.string({ required_error: 'Phone number required' })
	.regex(
		/^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/,
		{
			message: 'Invalid phone number',
		},
	)
export const PhoneSchema = z.object({
	number: PhoneNumberSchema,
	type: z.string(),
})

export const CreatePhoneSchema = z.object({
	userId: z.string(),
	phoneType: z.string(),
	phoneNumber: PhoneNumberSchema,
})
export const UpdatePhoneSchema = z.object({
	userId: z.string(),
	id: z.string(),
	type: z.string(),
	number: PhoneNumberSchema,
	primary: z.boolean().optional().default(false),
})
export const DeletePhoneSchema = z.object({
	id: z.string().optional(),
})

export const DateSchema = z
	.string({ required_error: 'Date required' })
	.regex(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, {
		message: 'Invalid date',
	})

export const PasswordSchema = z
	.string({ required_error: 'Password is required' })
	.min(6, { message: 'Password is too short' })
	// NOTE: bcrypt has a limit of 72 bytes (which should be plenty long)
	// https://github.com/epicweb-dev/epic-stack/issues/918
	.refine((val) => new TextEncoder().encode(val).length <= 72, {
		message: 'Password is too long',
	})
export const NameSchema = z
	.string({ required_error: 'Name is required' })
	.min(3, { message: 'Name is too short' })
	.max(40, { message: 'Name is too long' })
export const EmailSchema = z
	.string({ required_error: 'Email is required' })
	.email({ message: 'Email is invalid' })
	.min(3, { message: 'Email is too short' })
	.max(100, { message: 'Email is too long' })
	.transform((value) => value.toLowerCase())

export const ProfileFormSchema = z.object({
	member: NameSchema.nullable().default(null),
	username: UsernameSchema,
	secondaryEmail: EmailSchema.optional(),
})
export const UserContactSchema = z.object({
	id: z.string(),
	member: NameSchema.optional(),
	username: UsernameSchema,
	primaryEmail: EmailSchema,
	secondaryEmail: EmailSchema.optional(),
})

export const PasswordAndConfirmPasswordSchema = z
	.object({ password: PasswordSchema, confirmPassword: PasswordSchema })
	.superRefine(({ confirmPassword, password }, ctx) => {
		if (confirmPassword !== password) {
			ctx.addIssue({
				path: ['confirmPassword'],
				code: 'custom',
				message: 'The passwords must match',
			})
		}
	})

export const AddressSchema = z.object({
	address: z.string(),
	parcelAndLot: z
		.object({
			parcel: z.string(),
			lot: z.string(),
		})
		.array(),
})
export const PortsSchema = z.object({
	ditch: z.number(),
	position: z.number(),
	entry: z.enum(['10-01', '10-03']),
	section: z.enum(['North', 'South', 'East', 'West']),
})
export const DepositsSchema = z.object({
	amount: z.number(),
	note: z.string(),
	date: DateSchema,
})

const stringRegex = /^\d{4}-[01]\d-[0-3]\d$/
export const ScheduleEditorSchema = z.object({
	id: z.string().optional(),
	source: z.string(),
	costPerHour: z.number(),
	date: z.string().regex(stringRegex),
	deadline: z.string().regex(stringRegex),
})

export const UserScheduleEditorSchema = z.object({
	userId: z.string(),
	scheduleId: z.string(),
	portId: z.string(),
	ditch: z.number(),
	hours: z.number().min(0).max(12).optional().default(0),
})

export const SurveyEditorSchema = z.object({
	id: z.string().optional(),
	name: z.string(),
	description: z.string(),
	active: z.boolean(),
})
